/* Variables */

$font-custom:                 'Source Sans Pro', $font-family-sans-serif;

$h1-font-size-mobile:                $font-size-base * 2.1;
$h2-font-size-mobile:                $font-size-base * 1.9;
$h3-font-size-mobile:                $font-size-base * 1.7;
$h4-font-size-mobile:                $font-size-base * 1.5;

$spacer-size:                 $spacer; // BS4 default spacer
$spacer-size--xs:             ($spacer-size / 3);
$spacer-size--sm:             ($spacer-size / 2);
$spacer-size--lg:             ($spacer-size * 2);

/* ************************* */


/* Modifiers */

$animation-time:      500ms;
$max-height:          500px;

.animation {
  &-collapse {
    &-enter {
      max-height: $max-height;
      opacity: 1;
    }
    &-enter-active {
      max-height: 0;
      opacity: 0;
      transition: max-height $animation-time, opacity $animation-time;
    }
    &-enter-done {
      max-height: 0;
      opacity: 0;
    }
    &-exit {
      max-height: 0;
      opacity: 0;
    }
    &-exit-active {
      max-height: $max-height;
      opacity: 1;
      transition: max-height $animation-time, opacity $animation-time;
    }
    &-exit-done {
      max-height: $max-height;
      opacity: 1;
    }
  }

  &-logo-resize {
    &-enter {
      transform: scale(1);
    }
    &-enter-active {
      transform: scale(.8);
      transition: transform $animation-time;
    }
    &-enter-done {
      transform: scale(.8);
    }
    &-exit {
      transform: scale(.8)
    }
    &-exit-active {
      transform: scale(1);
      transition: transform $animation-time;
    }
    &-exit-done {
      transform: scale(1);
    }
  }
}




/* ************************* */


/* Modifiers */

@include media-breakpoint-down(sm) {
  h1, .h1 { font-size: $h1-font-size-mobile }
  h2, .h2 { font-size: $h2-font-size-mobile }
  h3, .h3 { font-size: $h3-font-size-mobile }
  h4, .h4 { font-size: $h4-font-size-mobile }
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
  text-transform: uppercase;
  font-family: $font-custom;
  letter-spacing: -1px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1, h2, .h1, .h2 {
  text-align: center;
}

p, figure {
  margin: 0 0 $line-height-base;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  &.h1, &.h2, &.h3, &.h4, &.h5 {
      color: $text-color;
  }
}

.btn {
  text-transform: uppercase;
  font-family: $font-custom;
  font-weight: bold;
}

a {
  font-weight: bold;
}

.img-responsive {
  &--sm {
      max-width: 75%;

      @include media-breakpoint-up(sm) {
          max-width: 50%;
      }
  }

  &--md {
      max-width: 75%;
  }
}


/* ************************* */


/* Objects */

$logo-height--xs:               120px;
$logo-height--sm:               175px;

.logo {
  width: auto;
  height: $logo-height--xs;

  @include media-breakpoint-up(sm) {
      height: $logo-height--sm;
  }
}

.spacer {
  height: $spacer-size;

  &--xs { height: $spacer-size--xs; }
  &--sm { height: $spacer-size--sm; }
  &--lg { height: $spacer-size--lg; }
}

#brand-box {
  text-align: center;
  margin-top: $spacer-size;

  @media (min-width: $grid-breakpoints(sm)) {
      margin-top: $spacer-size--lg;
  }
}

.section {
  padding: $spacer-size 0;

  @include media-breakpoint-up(sm) {
      padding: $spacer-size--lg 0;
  }
}

.region {
  margin: $spacer auto;
  padding: $spacer-size;
  border: 3px solid $gray-100;

  .figure {
    margin-bottom: 0;
    display: inline-block;
  }
}

// Card-deck duplicate
.card-deck-mod {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin * 2;
  }

  @include media-breakpoint-up(md) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}

// Scrol to top button
.scroll-to-top {
  &-container {
    transform: scale(.75);
    position: fixed;
    right: -100px;
    bottom: 150px;
    transition: right 0.5s;
    cursor: pointer;
    background: white;
    padding: 3px;
    @extend .rounded;
  }

  &-transition {
    right: 10px;
  }
}

// Up arrow
[class*="icono"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  &:after, &:before {
    content: "";
    box-sizing: border-box;
  }
}
[class*="icono-arrow3"]{
  width: 18px;
  height: 20px;
  border-width: 4px 4px 0 0;
  border-style: solid;
  margin: 10px;
  border-radius: 0 2px 0 0;
  &:before, &:after{
    border-radius: 2px;
    position: absolute;
  }
  &:before{
    right: 0;
    top: -3px;
    width: 23px;
    height: 4px;
    transform: rotate(-45deg);
    transform-origin: right top;
    box-shadow: inset 0 0 0 32px;
  }
  &:after{
    width: 4px;
    height: 4px;
    left: -2px;
    top: -4px;
    box-shadow: inset 0 0 0 32px, 16px 17px;
  }
  &[class*="-up"]{
    transform: rotate(-45deg);
  }
}

/* ************************* */