/* Variable overrides */

$brand-accent:                #A63C2B;
$brand-lighter:               #D8F2F0;
$brand-light:                 #3E8C84;
$brand-normal:                #296B73;
$brand-dark:                  #194759;
$brand-darker:                #0F2D40;

$primary:                     $brand-light;
$success:                     $brand-accent;

$font-family-sans-serif:      "Century Gothic", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 2.8;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 2.0;
$h4-font-size:                $font-size-base * 1.7;
$h5-font-size:                $font-size-base * 1.5;

$text-color:                  #333;
$headings-color:              $brand-normal;
$link-color:                  $brand-light;

$grid-gutter-width:           10px;

/* ************************* */